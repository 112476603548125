
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as service from '../../services/service';

function CityCoordinatorFeedback() {
    const [state, setState] = useState({
        feedbackId: 0, faithRemarks: '', participationRemarks: '', integrityRemarks: '', teamWorkRemarks: '',
        emotionalRemarks: '', disabilityRemarks: '', misbehaveRemarks: '', economicRemarks: '',
        asset: '', assetRemarks: '', strengths: '', strengthRemarks: '', successRemarks: ''
    });
    // const [data, setData] = useState([]);
    const [applicationId, setApplicationId] = useState('');
    const [errors, setErrors] = useState({});
    // const [ccFeedback, setCCFedback] = useState(false);
    const [dcFeedback, setDCFedback] = useState(false);
    // const [scFeedback, setSCFedback] = useState(false);
    const [loading, setLoading] = useState(false);
    const [faith, setFaith] = useState('');
    const [centerParticipation, setCenterParticipation] = useState('');
    const [regionalSeminar, setRegionalSeminar] = useState('');
    const [nationalSeminar, setNationalSeminar] = useState('');
    const [integrity, setIntegrity] = useState('');
    const [teamWork, setTeamWork] = useState('');
    const [emotional, setEmotional] = useState('');
    const [disability, setDisability] = useState('');
    const [misBehave, setMisbehave] = useState('');
    const [economic, setEconomic] = useState('');
    const [success, setSuccess] = useState('');
    const disabilityData = [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }];
    const misbehaveData = [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }];
    const faithData = [{ label: "Total", value: "Total" }, { label: "Partly", value: "Partly" },
    { label: "Orientation Phase", value: "Orientation Phase" }, { label: "No Observation", value: "No Observation" }];

    const particpationData = [{ label: "100", value: "100" }, { label: "75", value: "75" },
    { label: "50", value: "50" }, { label: "Below 50", value: "Below 50" }];

    const integrityData = [{ label: "High", value: "High" }, { label: "Normal", value: "Normal" }, { label: "Doubtful", value: "Doubtful" }];
    const teamWorkData = [{ label: "Outstanding", value: "Outstanding" }, { label: "Fair", value: "Fair" }, { label: "Poor", value: "Poor" }];
    const emotionalData = [{ label: "High", value: "High" }, { label: "Normal", value: "Normal" }, { label: "Poor", value: "Poor" }];
    const economicData = [{ label: "Poor", value: "Poor" }, { label: "Good", value: "Good" }, { label: "Very Good", value: "Very Good" }];
    const successData = [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }, { label: "No Comments", value: "No Comments" }];

    const navigate = useNavigate();
    const params = useParams();

    const getFeedback = (appId) => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_CITY_COORDINATOR_FEEDBACK,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ApplicationId: appId,
                        RegistrationId: params.id
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, feedbackId: result.feedbackId, faithRemarks: result.faithRemarks, participationRemarks: result.participationRemarks, integrityRemarks: result.integrityRemarks,
                            teamWorkRemarks: result.teamWorkRemarks, emotionalRemarks: result.emotionalRemarks, disabilityRemarks: result.disabilityRemarks,
                            misbehaveRemarks: result.misbehaveRemarks, economicRemarks: result.economicRemarks, asset: result.asset, assetRemarks: result.assetRemarks,
                            strengths: result.strengths, strengthRemarks: result.strengthRemarks, successRemarks: result.successRemarks
                        });

                        const fth = ({ value: result.faithInSy, label: result.faithInSy });
                        setFaith(fth);
                        const centerPart = ({ value: result.participationInCenter, label: result.participationInCenter });
                        setCenterParticipation(centerPart);
                        const regPart = ({ value: result.participationInRegionalSeminar, label: result.participationInRegionalSeminar });
                        setRegionalSeminar(regPart);
                        const natPart = ({ value: result.participationInNationalSeminar, label: result.participationInNationalSeminar });
                        setNationalSeminar(natPart);
                        const intg = ({ value: result.integrity, label: result.integrity });
                        setIntegrity(intg);
                        const tw = ({ value: result.teamWork, label: result.teamWork });
                        setTeamWork(tw);
                        const emot = ({ value: result.emotionalStability, label: result.emotionalStability });
                        setEmotional(emot);
                        const disab = ({ value: result.physicalDisability, label: result.physicalDisability });
                        setDisability(disab);
                        const beh = ({ value: result.misbehaviour, label: result.misbehaviour });
                        setMisbehave(beh);
                        const eco = ({ value: result.economicCondition, label: result.economicCondition });
                        setEconomic(eco);
                        const suc = ({ value: result.marriageSuccess, label: result.marriageSuccess });
                        setSuccess(suc);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    const getApplicationById = () => {
        setLoading(true);
        // const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_APPLICATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        RegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        // setData(result);
                        // setCCFedback(result.cityCoordinatorFeedback);
                        setDCFedback(result.districtCoordinatorFeedback);
                        // setSCFedback(result.stateCoordinatorFeedback);

                        setApplicationId(result.applicationId);
                        getFeedback(result.applicationId);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };
   

    useEffect(() => {
        setLoading(true);
        getApplicationById();       
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!faith) {
            isValid = false;
            errs[".faith"] = "Please select Faith/Conviction in Sahaja Yoga";
        }
        if (!centerParticipation) {
            isValid = false;
            errs[".centerParticipation"] = "Please select Participation in Centre Activities";
        }
        if (!regionalSeminar) {
            isValid = false;
            errs[".regionalSeminar"] = "Please select Participation in Regional Seminars";
        }
        if (!nationalSeminar) {
            isValid = false;
            errs[".nationalSeminar"] = "Please select Participation in National Seminars ";
        }
        if (!integrity) {
            isValid = false;
            errs[".integrity"] = "Please select Integrity";
        }
        if (!teamWork) {
            isValid = false;
            errs[".teamWork"] = "Please select Team Worker";
        }
        if (!emotional) {
            isValid = false;
            errs[".emotional"] = "Please select Emotional Stability";
        }
        if (!disability) {
            isValid = false;
            errs[".disability"] = "Please select Any Physical Disability";
        }
        if (!misBehave) {
            isValid = false;
            errs[".misBehave"] = "Please select Misconduct / Misbehaviour";
        }
        if (!economic) {
            isValid = false;
            errs[".economic"] = "Please select Economic Condition of Self And Family";
        }
        if (!success) {
            isValid = false;
            errs[".success"] = "Please select Will he/she make Sahaja Yoga marriage successful ";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
       if (validate()) {
            fetch(
                service.ADD_UPDATE_CITY_COORDINATOR_FEEDBACK,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        FeedbackId: state.feedbackId ? state.feedbackId : 0,
                        RegistrationId: params.id,
                        ApplicationId: applicationId,
                        FaithInSy: faith.value,
                        FaithRemarks: state.faithRemarks,
                        ParticipationInCenter: centerParticipation.value,
                        ParticipationInRegionalSeminar: regionalSeminar.value,
                        ParticipationInNationalSeminar: nationalSeminar.value,
                        ParticipationRemarks: state.participationRemarks,
                        Integrity: integrity.value,
                        IntegrityRemarks: state.integrityRemarks,
                        TeamWork: teamWork.value,
                        TeamWorkRemarks: state.teamWorkRemarks,
                        EmotionalStability: emotional.value,
                        EmotionalStabilityRemarks: state.emotionalRemarks,
                        PhysicalDisability: disability.value,
                        PhysicalDisabilityRemarks: state.disabilityRemarks,
                        Misbehaviour: misBehave.value,
                        MisbehaviourRemarks: state.misbehaveRemarks,
                        EconomicCondition: economic.value,
                        EconomicConditionRemarks: state.economicRemarks,
                        AssetForSY: state.asset,
                        AssetForSYRemarks: state.assetRemarks,
                        Strengths: state.strengths,
                        StrengthRemarks: state.strengthRemarks,
                        MarriageSuccess: success.value,
                        MarriageSuccessRemarks: state.successRemarks,
                        AddedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/marriage/marriageforms');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/marriage/marriageforms');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center', }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    Faith/Conviction in Sahaja Yoga
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="faith"
                                                        name="faith"
                                                        options={faithData}
                                                        value={faith}
                                                        onChange={ev => setFaith(ev)}
                                                        placeholder="Select"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".faith"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.faithRemarks || ''}
                                                        name="faithRemarks"
                                                        onChange={changeHandler}
                                                        id="faithRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".faithRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Participation in Centre Activities : (In % terms)
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="centerParticiaption"
                                                        name="centerParticipation"
                                                        options={particpationData}
                                                        value={centerParticipation}
                                                        onChange={ev => setCenterParticipation(ev)}
                                                        placeholder="Select"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".centerParticipation"]}</div>
                                                </TableCell>
                                                <TableCell rowSpan={3}>
                                                    <MDInput label="Remarks" fullWidth value={state.participationRemarks || ''}
                                                        name="participationRemarks"
                                                        onChange={changeHandler}
                                                        id="participationRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".participationRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Participation in Regional Seminars : (In % terms)
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="regionalSeminar"
                                                        name="regionalSeminar"
                                                        options={particpationData}
                                                        value={regionalSeminar}
                                                        onChange={ev => setRegionalSeminar(ev)}
                                                        placeholder="Select"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".regionalSeminar"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Participation in National Seminars : (In % terms)
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="nationalSeminar"
                                                        name="nationalSeminar"
                                                        options={particpationData}
                                                        value={nationalSeminar}
                                                        onChange={ev => setNationalSeminar(ev)}
                                                        placeholder="Select"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".nationalSeminar"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Integrity
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="integrity"
                                                        name="integrity"
                                                        options={integrityData}
                                                        value={integrity}
                                                        onChange={ev => setIntegrity(ev)}
                                                        placeholder="Select"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".integrity"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.integrityRemarks || ''}
                                                        name="integrityRemarks"
                                                        onChange={changeHandler}
                                                        id="integrityRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".integrityRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Team Worker
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="teamWork"
                                                        name="teamWork"
                                                        options={teamWorkData}
                                                        value={teamWork}
                                                        onChange={ev => setTeamWork(ev)}
                                                        placeholder="Select"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".teamWork"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.teamWorkRemarks || ''}
                                                        name="teamWorkRemarks"
                                                        onChange={changeHandler}
                                                        id="teamWorkRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".teamWorkRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Emotional Stability
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="emotional"
                                                        name="emotional"
                                                        options={emotionalData}
                                                        value={emotional}
                                                        onChange={ev => setEmotional(ev)}
                                                        placeholder="Select"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emotional"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.emotionalRemarks || ''}
                                                        name="emotionalRemarks"
                                                        onChange={changeHandler}
                                                        id="emotionalRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emotionalRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Any Physical Disability
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="disability"
                                                        name="disability"
                                                        options={disabilityData}
                                                        value={disability}
                                                        onChange={ev => setDisability(ev)}
                                                        placeholder="Select"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".disability"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.disabilityRemarks || ''}
                                                        name="disabilityRemarks"
                                                        onChange={changeHandler}
                                                        id="disabilityRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".disabilityRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Any Reported Misconduct / Misbehaviour at Centre or Outside
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="misBehave"
                                                        name="misBehave"
                                                        options={misbehaveData}
                                                        value={misBehave}
                                                        onChange={ev => setMisbehave(ev)}
                                                        placeholder="Select"                                                        
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".misBehave"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.misbehaveRemarks || ''}
                                                        name="misbehaveRemarks"
                                                        onChange={changeHandler}
                                                        id="misbehaveRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".misbehaveRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Economic Condition of Self And Family
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="economic"
                                                        name="economic"
                                                        options={economicData}
                                                        value={economic}
                                                        onChange={ev => setEconomic(ev)}
                                                        placeholder="Select"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".economic"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.economicRemarks || ''}
                                                        name="economicRemarks"
                                                        onChange={changeHandler}
                                                        id="economicRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".economicRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Can he / she be considered an asset for Sahaja Yoga family
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="asset" fullWidth value={state.asset || ''}
                                                        name="asset"
                                                        onChange={changeHandler}
                                                        id="asset"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".asset"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.assetRemarks || ''}
                                                        name="assetRemarks"
                                                        onChange={changeHandler}
                                                        id="assetRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".assetRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Three strengths of his / her Character and personality
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="strengths" fullWidth value={state.strengths || ''}
                                                        name="strengths"
                                                        onChange={changeHandler}
                                                        id="strengths"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".strengths"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.strengthRemarks || ''}
                                                        name="strengthRemarks"
                                                        onChange={changeHandler}
                                                        id="strengthRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".strengthRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Will he/she make Sahaja Yoga marriage successful and happy
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="success"
                                                        name="success"
                                                        options={successData}
                                                        value={success}
                                                        onChange={ev => setSuccess(ev)}
                                                        placeholder="Select"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".success"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.successRemarks || ''}
                                                        name="successRemarks"
                                                        onChange={changeHandler}
                                                        id="successRemarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".successRemarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <MDButton color="info" onClick={submitHandler} disabled={dcFeedback}>
                                            SAVE
                                        </MDButton>
                                    }                                    
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CityCoordinatorFeedback;