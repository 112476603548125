
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
// import { Alert } from "bootstrap";
import * as service from '../../../../services/service';

function AddEditCity() {
    const [state, setState] = useState({ city: '' });
    const [countryData, setCountryData] = useState([]);
    const [countryId, setCountryId] = useState();
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const params = useParams();
    const getCounrties = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_CITY_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        CityId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {                    
                    if (result.message.messageType === 'Success') {
                        setState({ ...state, city: result.city });
                        const con = ({ value: result.countryId, label: result.country });
                        setCountryId(con);
                        getStates(result.countryId);
                        const sta = ({ value: result.stateId, label: result.state });
                        setStateId(sta);
                        getDistricts(result.stateId)
                        const dt = ({ value: result.districtId, label: result.district });
                        setDistrictId(dt);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getCounrties();
        if (params.id > 0) {
            getDetailsById();
        }
        else {
            const def = { value: 1, label: 'India' };
            setCountryId(def);
            getStates(def.value);
        }

    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!countryId) {
            isValid = false;
            errs[".country"] = "Please select country";
        }
        if (!stateId) {
            isValid = false;
            errs[".state"] = "Please select state";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select district";
        }
        if (!state.city) {
            isValid = false;
            errs[".city"] = "Please enter city";
        }
        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateId(null);
        setDistrictId(null);
        getStates(ev.value);
    }

    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }

    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_CITY,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        CityId: params.id ? params.id : null,
                        City: state.city,
                        CountryId: countryId.value,
                        StateId: stateId.value,
                        DistrictId: districtId.value,
                        AddedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/city');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/city');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Dropdown menuClassName='myMenuClassName' id="country"
                                                name="country"
                                                options={countryData}
                                                value={countryId}
                                                onChange={countryChangeHandler}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                        <Select
                                            options={stateData}
                                            name="state"
                                            value={stateId}
                                            onChange={stateChangeHandler}
                                            isSearchable
                                            styles={selectStyles}
                                            placeholder="Select State"
                                        />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                        <Select
                                            options={districtData}
                                            name="district"
                                            value={districtId}
                                            onChange={districtChangeHandler}
                                            isSearchable
                                            styles={selectStyles}
                                            placeholder="Select District"
                                        />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="City" fullWidth value={state.city || ''}
                                                name="city"
                                                onChange={changeHandler}
                                                id="city"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".city"]}</div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <MDButton color="info" onClick={submitHandler}>
                                                    SAVE
                                                </MDButton>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditCity;