/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// import Icon from "@mui/material/Icon";
// Authentication layout components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import * as service from '../../services/service';

function ViewRegistrationDetails() {
    const [data, setData] = useState([{}]);
    // const [errors, setErrors] = useState({});
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const role = JSON.parse(window.sessionStorage.getItem('userData')).userRole;
    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_REGISTRATIONS_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        RegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                        setVerified(result.isVerified);                        
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };
    useEffect(() => {
        setLoading(true);
        getRegistrationById();
        setLoading(false);
    }, []);

    const backHandler = () => {
        navigate('/marriage/registrations');
    };

    const handleDownload = (fileName, filePath, id) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        formData.append("RegistrationId", id);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    const submitHandler = e => {        
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (window.confirm("Are you sure? Do you want to verify this record?")) {
            fetch(
                service.UPDATE_REGISTRATION_VERIFICATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        RegistrationId: params.id,
                        IsVerified: 1,
                        AddedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/marriage/registrations');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/marriage/registrations');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        // setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ width: '100%' }}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Registration Details
                        </MDTypography>
                    </MDBox>
                    {loading ? <Oval
                        heigth="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    /> :
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form">
                                {/* <div style={{ fontSize: '12px', color: 'red' }}>{errors}</div> */}
                                <Card style={{ alignItems: 'center' }}>
                                    <MDBox pt={4} pb={3} px={3} style={{ width: '100%' }}>
                                        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                            <Table id="tblReceipt" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="center" style={{ fontWeight: 'bold', fontSize: '16px', color: 'blue' }}>
                                                            H.H. SHRI MATAJI NIRMALA DEVI SAHAJAYOGA TRUST - PUJA MAHOSTAV
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="center" style={{ fontWeight: 'bold', fontSize: '14px', backgroundColor: 'darkgray' }}>
                                                            BIRTHDAY PUJA 2023 MARRIAGE REGISTRATION DETAILS (CHHINDAWADA, MADHYAPRADESH)
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Name :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.firstName} {data.middleName} {data.lastName}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Mobile No. :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.mobileNo}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Email Id :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.emailId}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        House/Plot/Flat No :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.flatNo}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Building Name :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.buildingName}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Street/Road No./Name :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.streetName}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Locality/Mohalla :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.locality}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Village :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.village}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        PIN :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.pinCode}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        City :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.city}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        District :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.district}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        State :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.state}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        PAN :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.pan}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Adhar Card No :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.adharCardNo}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Birth Date :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {moment(data.birthDate).format("DD/MM/YYYY")}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Age :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.age}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Gender :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.gender}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Realization Month-Year :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.realizationMonthYear}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Center :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.center}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Attending Since :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.attendingSince}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        City Coordinator Name :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.cityCoordinatorName}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        City Coordinator Contact :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.cityCoordinatorContact}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        City Coordinator EmailId :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {data.cityCoordinatorEmailId}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        PAN Document
                                                                        <MDButton onClick={() => handleDownload(data.panFileName, data.panFilePath, data.registrationId)}>{data.panFileName}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Adhar Card Document
                                                                        <MDButton onClick={() => handleDownload(data.adharFileName, data.adharFilePath, data.registrationId)}>{data.adharFileName}</MDButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </Table>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </MDBox>
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        <Table>
                                            <TableRow>
                                                <TableCell>
                                                    <MDButton color="info" onClick={submitHandler} disabled={verified || role !== "City"}>
                                                        Verify
                                                    </MDButton>
                                                </TableCell>
                                                <TableCell>
                                                    <MDButton color="secondary" onClick={backHandler}>
                                                        Back
                                                    </MDButton>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </MDBox>
                                </Card>
                            </MDBox>
                        </MDBox>

                    }
                </Card>
            </div>
        </DashboardLayout>
    );
}

export default ViewRegistrationDetails;
