
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { Circles } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Radio } from '@mui/material';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import Select from 'react-select';
import { styled } from '@mui/material/styles';

import * as service from '../../services/service';

function Reports() {
    const [reportDetailsData, setReportDetailsData] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [cityId, setCityId] = useState('');
    const [cityData, setCityData] = useState([]);
    const [eventId, setEventId] = useState('');
    const [eventData, setEventData] = useState([]);
    const [showDetailsReport, setShowDetailsReport] = useState(false);
    const [reportType, setReportType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [stateUser, setStateUser] = useState(false);
    const [districtUser, setDistrictUser] = useState(false);
    const [cityUser, setCityUser] = useState(false);
    const [marritalstatus, setMarritalStatus] = useState('');
    const marritalStatusData = [{ label: "All", value: "" }, { label: "Single", value: "Single" }, { label: "Married", value: "Married" }, { label: "Widow", value: "Widow" },
    { label: "Widower", value: "Widower" }, { label: "Divorcee", value: "Divorcee" }, { label: "Separated but not divorced", value: "Separated but not divorced" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getEvents = () => {
        fetch(
            service.GET_EVENTS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.eventId, label: item.event }));
                setEventData(data2);
            });
    };

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setStateData(data2);
                setStateId(all);

                if (JSON.parse(window.sessionStorage.getItem('userData')).userRole !== 'SuperAdmin') {
                    const st = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).stateId);
                    setStateId(st);
                }
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));

                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setDistrictData(data2);
                setDistrictId(all);

                if (JSON.parse(window.sessionStorage.getItem('userData')).userRole !== 'SuperAdmin') {
                    const dt = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).districtId);
                    setDistrictId(dt);
                }
            });
    };

    const getCities = (id) => {
        fetch(
            service.GET_CITY_BY_DISTRICT_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    DistrictId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.cityId, label: item.city }));

                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setCityData(data2);
                setCityId(all);

                if (JSON.parse(window.sessionStorage.getItem('userData')).userRole !== 'SuperAdmin') {
                    const dt = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).cityId);
                    setCityId(dt);
                }
            });
    };
    useEffect(() => {
        getCountries();
        getEvents();

        const def = { value: 1, label: 'India' };
        setCountryId(def);
        getStates(def.value);

        if (JSON.parse(window.sessionStorage.getItem('userData')).userRole !== 'SuperAdmin') {
            getDistricts(JSON.parse(window.sessionStorage.getItem('userData')).stateId);
        }

        if (JSON.parse(window.sessionStorage.getItem('userData')).userRole === 'State') {
            setStateUser(true);
        }
        else if (JSON.parse(window.sessionStorage.getItem('userData')).userRole === 'District') {
            setDistrictUser(true);
        }
        else if (JSON.parse(window.sessionStorage.getItem('userData')).userRole === 'City') {
            setCityUser(true);
        }

        const type = { value: 1, label: 'BIRTHDAY PUJA MAHOSTAV 2023' };
        setEventId(type);
        const status = { value: '', label: 'All' };
        setMarritalStatus(status);
    }, []);

    // const validate = () => {
    //     let isValid = true;
    //     const errs = {};
    //     if (!stateId) {
    //         isValid = false;
    //         errs[".state"] = "Please select state";
    //     }
    //     setErrors(errs);
    //     return isValid;
    // }

    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateData(null);
        setDistrictData(null);
        setCityData(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictData(null);
        setCityData(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
        setCityData(null);
        getCities(ev.value);
    }
    const cityChangeHandler = (ev) => {
        setCityId(ev);
    }
    const eventChangeHandler = (ev) => {
        setEventId(ev);
    }
    const marritalStatusChangeHandler = (ev) => {
        setMarritalStatus(ev);
    }

    const handleChange = (event) => {
        setShowDetailsReport(false);
        setReportType(event.target.value);
    };

    function GetApplicationReport() {
        const errs = {};
        fetch(
            service.GET_APPLICATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    CityId: cityId.value ? cityId.value : 0,
                    EventId: eventId.value,
                    MarritalStatus: marritalstatus.value
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }

    const submitHandler = e => {
        setShowDetailsReport(false);
        e.preventDefault();
        setLoading(true);
        if (!reportType) {
            // eslint-disable-next-line
            alert("Please select report type");
        }
        if (reportType === "applicationdetails") {
            GetApplicationReport();
        }
        setLoading(false);
    }

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));
    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

    const ShowDetailsReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvDetailReport">
                <TableBody>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>ApplicationNo</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Gender</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>EmailId</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Mobile No</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>BirthDate</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PAN</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>AdharCardNo</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FlatNo</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>BuildingName</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>StreetName</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Locality</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Village</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PinCode</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>State</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>City</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>RealizationMonthYear</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Center</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>AttendingSince</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>CityCoordinatorName</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>CityCoordinatorContact</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>CityCoordinatorEmailId</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherName</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Relation</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherOccupation</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherAnnualIncome</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherFlatNo</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherBuildingName</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherStreetName</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherLocality</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherVillage</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherPinCode</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherState</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherDistrict</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherCity</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherMobileNo</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>FatherEmailId</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Occupation</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>AnnualIncome</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Designation</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>OrganizationName</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>NatureOfWork</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Height</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Weight</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Complextion</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Handicap</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Hobbies</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PreviosHealthProblem</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PresentHealthProblem</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>MarritalStatus</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>JointFamily</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>RelationsStayingTogether</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>SahajMarriageEarlier</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>EarlierYear</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>EarlierSelection</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>ReasonOfNotMarried</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>RelocateCountry</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>RelocateState</TableCell>
                    </TableRow>
                    {reportDetailsData.map((row) => (
                        <TableRow
                            key={row.donorId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px' }}>{row.applicationNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.firstName} {row.middleName} {row.lastName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.gender}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.emailId}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.mobileNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{moment(row.birthDate).format("DD/MM/YYYY")}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.pan}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.adharCardNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.flatNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.buildName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.streetaName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.locality}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.village}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.pinCode}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.state}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.city}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.realizationMonthYear}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.center}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.attendingSince}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.cityCoordinatorName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.cityCoordinatorContact}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.cityCoordinatorEmailId}</TableCell>

                            <TableCell style={{ fontSize: '14px' }}>{row.fatherName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.relation}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherOccupation}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherAnnualIncome}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherFlatNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherBuildingName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherStreetName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherLocality}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherVillage}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherPinCode}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherState}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherDistrict}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherCity}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherMobileNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.fatherEmailId}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.occupation}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.annualIncome}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.designation}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.organizationName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.natureOfWork}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.height}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.weight}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.complextion}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.handicap}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.hobbies}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.previosHealthProblem}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.presentHealthProblem}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.marritalStatus}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.jointFamily}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.relationsStayingTogether}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.sahajMarriageEarlier}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.earlierYear}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.earlierSelection}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.reasonOfNotMarried}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.relocateCountry}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.relocateState}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    ));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Reports
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                    Marriage Application Details
                                                    <Radio
                                                        checked={reportType === 'applicationdetails'}
                                                        onChange={handleChange}
                                                        value="applicationdetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Marriage Application Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '20%', fontSize: '14px' }}>
                                                    <Dropdown menuClassName='myMenuClassName' id="event"
                                                        name="event"
                                                        options={eventData}
                                                        value={eventId}
                                                        onChange={eventChangeHandler}
                                                        label="Event"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".event"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '25%', fontSize: '14px' }}>
                                                    <Dropdown menuClassName='myMenuClassName' id="country"
                                                        name="country"
                                                        options={countryData}
                                                        value={countryId}
                                                        onChange={countryChangeHandler}
                                                        label="Country"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '25%', fontSize: '14px' }}>
                                                    <Select
                                                        options={stateData}
                                                        name="state"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                        isSearchable
                                                        isDisabled={stateUser || districtUser}
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '14px' }}>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        isDisabled={districtUser}
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px' }}>
                                                    <Select className="basic-single"
                                                        options={cityData}
                                                        name="city"
                                                        value={cityId}
                                                        onChange={cityChangeHandler}
                                                        isSearchable
                                                        isDisabled={cityUser}
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".city"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px' }}>
                                                    <Select className="basic-single"
                                                        options={marritalStatusData}
                                                        name="marritalstatus"
                                                        value={marritalstatus}
                                                        onChange={marritalStatusChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".marritalstatus"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    <MDBox mt={4} mb={1} textAlign="center">
                                                        {loading ?
                                                            <Circles
                                                                heigth="20"
                                                                width="20"
                                                                color='grey'
                                                                ariaLabel='loading'
                                                            />

                                                            :
                                                            <MDButton color="info" onClick={submitHandler}>
                                                                Show
                                                            </MDButton>
                                                        }
                                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                {showDetailsReport ? <ShowDetailsReport /> : null}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">
                                                {showDetailsReport ?
                                                    <ReactHTMLTableToExcel table="dvDetailReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Reports;
