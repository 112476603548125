// const baseURL = 'https://localhost:44379/api/';
 const baseURL = 'https://sahajmarriageapi.sahajayogaindia.org.in/api/';

export const LOGIN_URL = `${baseURL}Account/userlogin`;
export const COUNTRY_URL = `${baseURL}Masters/getcountries`;
export const GET_COUNTRY_BY_ID = `${baseURL}Masters/getcountrybyid`;
export const ADD_UPDATE_COUNTRY = `${baseURL}Masters/addeditcountry`;
export const STATE_URL = `${baseURL}Masters/getstates`;
export const GET_STATE_BY_ID = `${baseURL}Masters/getstatebyid`;
export const GET_STATE_BY_COUNTRY_ID = `${baseURL}Masters/getstatesbycountryid`;
export const ADD_UPDATE_STATE = `${baseURL}Masters/addeditstate`;
export const DISTRICT_URL = `${baseURL}Masters/getdistricts`;
export const GET_DISTRICT_BY_STATE_ID = `${baseURL}Masters/getdistrictsbystateid`;
export const GET_DISTRICT_BY_ID = `${baseURL}Masters/getdistrictbyid`;
export const ADD_UPDATE_DISTRICT = `${baseURL}Masters/addeditdistrict`;
export const GET_CITY_BY_ID = `${baseURL}Masters/getcitybyid`;
export const GET_CITY_BY_DISTRICT_ID = `${baseURL}Masters/getcitiesbydistrictid`;
export const ADD_UPDATE_CITY = `${baseURL}Masters/addeditcity`;
export const USERS_URL = `${baseURL}Masters/getusers`;
export const GET_ACTIVE_USERS = `${baseURL}Masters/getactiveusers`;
export const GET_USER_BY_ID = `${baseURL}Masters/getuserbyid`;
export const ADD_UPDATE_USER = `${baseURL}Masters/addedituser`;
export const UPDATE_USER_STATUS = `${baseURL}Masters/updateuserstatus`;
export const GET_EVENTS = `${baseURL}Masters/getevents`;

export const GET_REGISTRATIONS_BY_SEARCH = `${baseURL}Admin/getregistrationbysearch`;
export const GET_REGISTRATIONS_BY_ID = `${baseURL}Admin/getregistrationbyid`;
export const UPDATE_REGISTRATION_VERIFICATION = `${baseURL}Admin/updateregistrationverification`;
export const GET_APPLICATIONS_BY_SEARCH = `${baseURL}Admin/getapplicationsbysearch`;
export const DOWNLOAD_FILE = `${baseURL}Admin/downloadfile`;
export const GET_CITY_COORDINATOR_FEEDBACK = `${baseURL}Admin/getcitycoordinatorfeedback`;
export const ADD_UPDATE_CITY_COORDINATOR_FEEDBACK = `${baseURL}Admin/addeditcitycoordinatorfeedback`;
export const GET_DISTRICT_COORDINATOR_FEEDBACK = `${baseURL}Admin/getdistrictcoordinatorfeedback`;
export const ADD_UPDATE_DISTRICT_COORDINATOR_FEEDBACK = `${baseURL}Admin/addeditdistrictcoordinatorfeedback`;
export const GET_STATE_COORDINATOR_FEEDBACK = `${baseURL}Admin/getstatecoordinatorfeedback`;
export const ADD_UPDATE_STATE_COORDINATOR_FEEDBACK = `${baseURL}Admin/addeditstatecoordinatorfeedback`;
export const GET_TOTAL_COUNTS = `${baseURL}Admin/gettotalcounts`;
export const GET_CITY_WISE_COUNT = `${baseURL}Admin/getcitywisecounts`;
export const GET_APPLICATION_REPORT = `${baseURL}Admin/getapplicationsreport`;

export const GET_APPLICATION_BY_ID = `${baseURL}Applicant/getmarriageapplicationbyid`;


export default LOGIN_URL;