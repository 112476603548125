import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import * as service from '../../services/service'

function MarriageForms() {
    const [search, setSearch] = useState('');
    const [eventId, setEventId] = useState('');
    const [eventData, setEventData] = useState([]);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const getEvents = () => {
        fetch(
            service.GET_EVENTS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.eventId, label: item.event }));
                setEventData(data2);
            });
    };
    const submitHandler = (evId) => {
        fetch(
            service.GET_APPLICATIONS_BY_SEARCH,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Search: search,
                    EventId: evId,
                    CityId: JSON.parse(window.sessionStorage.getItem('userData')).cityId,
                    DistrictId: JSON.parse(window.sessionStorage.getItem('userData')).districtId,
                    StateId: JSON.parse(window.sessionStorage.getItem('userData')).stateId,
                    UserRole: JSON.parse(window.sessionStorage.getItem('userData')).userRole
                })
            })
            .then(res => res.json())
            .then(result => {
                console.log(result);
                if (result[0].message.messageType === 'Failed') {
                    setData([]);
                }
                else {
                    setData(result);
                }
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const eventChangeHandler = (ev) => {
        setEventId(ev);
    }
    const handleEdit = (id) => {
        navigate(`/viewmarriageform/${id}`);
    }

    useEffect(() => {
        setLoading(true);
        getEvents();
        const def = { value: 1, label: 'BIRTHDAY PUJA MAHOSTAV 2023' };
        setEventId(def);
        setEventId(def);
        submitHandler(def.value);
        setLoading(false);
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div>
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Marriage Applications
                                    </MDTypography>
                                </MDBox>
                                <br />
                                <MDBox mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}>
                                    <Stack direction="row" spacing={5}>
                                        <MDInput label="search"
                                            value={search}
                                            name="search"
                                            onChange={ev => setSearch(ev.target.value)}
                                            id="search"
                                            required
                                        />
                                        <div style={{ width: '30%', fontSize: '14px' }}>
                                            <Dropdown menuClassName='myMenuClassName' id="event"
                                                name="event"
                                                options={eventData}
                                                value={eventId}
                                                onChange={eventChangeHandler}
                                                label="Event"
                                            />
                                        </div>
                                        <MDButton color="info" endIcon={<SearchIcon />} onClick={submitHandler}>
                                            Search
                                        </MDButton>
                                    </Stack>
                                    <br />
                                    <div style={{ fontSize: '12px', color: 'green' }}>Search by FirstName, LastName, Mobile No. or Email Id</div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{error}</div>
                                </MDBox>
                                <br />
                                <Card style={{ alignItems: 'center' }}>
                                    {loading ? <Oval
                                        heigth="100"
                                        width="100"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                            <Table aria-label="simple table">
                                                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Application No.</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>                                                    
                                                    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Mobile No.</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>PAN</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Adhar Card No.</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>City</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>District</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>State</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Verified</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                </TableRow>
                                                <TableBody>
                                                    {data.map((row) => (
                                                        <TableRow
                                                            key={row.registration.registrationId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>{row.applicationNo}</TableCell>
                                                            <TableCell>{row.registration.firstName} {row.registration.middleName} {row.registration.lastName}</TableCell>                                                            
                                                            <TableCell>{row.registration.emailId}</TableCell>
                                                            <TableCell>{row.registration.mobileNo}</TableCell>
                                                            <TableCell>{row.registration.pan}</TableCell>
                                                            <TableCell>{row.registration.adharCardNo}</TableCell>
                                                            <TableCell>{row.registration.city}</TableCell>
                                                            <TableCell>{row.registration.district}</TableCell>
                                                            <TableCell>{row.registration.state}</TableCell>
                                                            <TableCell>{row.registration.isVerified ? 'Yes' : 'No'}</TableCell>
                                                            <TableCell><MDButton onClick={() => handleEdit(row.registration.registrationId)}>View Details</MDButton></TableCell>                                                            
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </div>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default MarriageForms;