
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
// import Dropdown from 'react-dropdown';
// import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as service from '../../services/service';

function DistrictCoordinatorFeedback() {
    const [state, setState] = useState({
        feedbackId: 0, periodInSy: '', behaviour: '', participation: '', recluse: '',
        feedbackfromOthers: '', parents: '', sahajCulture: '', importanceOfMarriage: '',
        applicantsReservations: '', parentsReservations: '', commitment: '', success: '', educationalCertificate: '',
        incomeSalary: '', healthProblem: '', divorcee: ''
    });
    // const [data, setData] = useState([]);
    const [applicationId, setApplicationId] = useState('');
    const [errors, setErrors] = useState({});
    // const [ccFeedback, setCCFedback] = useState(false);
    // const [dcFeedback, setDCFedback] = useState(false);
    const [scFeedback, setSCFedback] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();

    const getFeedback = (appId) => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_DISTRICT_COORDINATOR_FEEDBACK,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ApplicationId: appId,
                        RegistrationId: params.id
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, feedbackId: result.feedbackId, periodInSy: result.periodInSy, behaviour: result.behaviour, participation: result.participation,
                            recluse: result.recluse, feedbackfromOthers: result.feedbackFromOthers, parents: result.parents,
                            sahajCulture: result.sahajCulture, importanceOfMarriage: result.importanceOfMarriage, applicantsReservations: result.applicantsReservations,
                            parentsReservations: result.parentsReservations,
                            commitment: result.commitment, success: result.success, educationalCertificate: result.educationalCertificate,
                            incomeSalary: result.incomeSalary, healthProblem: result.healthProblem, divorcee: result.divorcee
                        });
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };


    const getApplicationById = () => {
        setLoading(true);
        // const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_APPLICATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        RegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        // setData(result);
                        // setCCFedback(result.cityCoordinatorFeedback);
                        // setDCFedback(result.districtCoordinatorFeedback);
                        setSCFedback(result.stateCoordinatorFeedback);

                        setApplicationId(result.applicationId);
                        getFeedback(result.applicationId);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    
    useEffect(() => {
        setLoading(true);
        getApplicationById();      
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.periodInSy) {
            isValid = false;
            errs[".periodInSy"] = "Please enter";
        }
        if (!state.behaviour) {
            isValid = false;
            errs[".behaviour"] = "Please enter";
        }
        if (!state.participation) {
            isValid = false;
            errs[".participation"] = "Please enter";
        }
        if (!state.recluse) {
            isValid = false;
            errs[".recluse"] = "Please enter";
        }
        if (!state.feedbackfromOthers) {
            isValid = false;
            errs[".feedbackfromOthers"] = "Please enter";
        }
        if (!state.parents) {
            isValid = false;
            errs[".parents"] = "Please enter";
        }
        if (!state.sahajCulture) {
            isValid = false;
            errs[".sahajCulture"] = "Please enter";
        }
        if (!state.importanceOfMarriage) {
            isValid = false;
            errs[".importanceOfMarriage"] = "Please enter";
        }
        if (!state.applicantsReservations) {
            isValid = false;
            errs[".applicantsReservations"] = "Please enter";
        }
        if (!state.parentsReservations) {
            isValid = false;
            errs[".parentsReservations"] = "Please enter";
        }
        if (!state.commitment) {
            isValid = false;
            errs[".commitment"] = "Please enter";
        }
        if (!state.success) {
            isValid = false;
            errs[".success"] = "Please enter";
        }
        if (!state.educationalCertificate) {
            isValid = false;
            errs[".educationalCertificate"] = "Please enter";
        }
        if (!state.incomeSalary) {
            isValid = false;
            errs[".incomeSalary"] = "Please enter";
        }
        if (!state.healthProblem) {
            isValid = false;
            errs[".healthProblem"] = "Please enter";
        }
        if (!state.divorcee) {
            isValid = false;
            errs[".divorcee"] = "Please enter";
        }
        
        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
        fetch(
            service.ADD_UPDATE_DISTRICT_COORDINATOR_FEEDBACK,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    FeedbackId: state.feedbackId ? state.feedbackId : 0,
                    RegistrationId: params.id,
                    ApplicationId: applicationId,
                    PeriodInSy: state.periodInSy,
                    Behavior: state.behaviour,
                    Participation: state.participation,
                    Recluse: state.recluse,
                    FeedbackFromOthers: state.feedbackfromOthers,
                    Parents: state.parents,
                    SahajCulture: state.sahajCulture,
                    ImportanceOfMarriage: state.importanceOfMarriage,
                    ApplicantsReservations: state.applicantsReservations,
                    ParentsReservations: state.parentsReservations,
                    Commitment: state.commitment,
                    Success: state.success,
                    EducationalCertificate: state.educationalCertificate,
                    IncomeSalary: state.incomeSalary,
                    HealthProblem: state.healthProblem,
                    Divorcee: state.divorcee,
                    AddedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                    UpdatedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.messageType === 'Success') {
                    // eslint-disable-next-line
                    alert(result.message);
                    navigate('/marriage/marriageforms');
                }
                else if (result.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message);
                    navigate('/marriage/marriageforms');
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center', }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell width="50%">
                                                    How long has been the candidate been in Sahaja
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.periodInSy || ''}
                                                        name="periodInSy"
                                                        onChange={changeHandler}
                                                        id="periodInSy"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".periodInSy"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    What has been his behavior and interaction within Sahaj Yoga and outside in his place of work?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.behaviour || ''}
                                                        name="behaviour"
                                                        onChange={changeHandler}
                                                        id="behaviour"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".behaviour"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Does the candidate participate in Sahaj Yoga activities and what is his/her level of devotion to the Divine Mother and Sahaja Yoga?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.participation || ''}
                                                        name="participation"
                                                        onChange={changeHandler}
                                                        id="participation"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".participation"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Does he get along well with the collectivity or is he/she is a recluse?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.recluse || ''}
                                                        name="recluse"
                                                        onChange={changeHandler}
                                                        id="recluse"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".recluse"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    What is the feedback/opinion of sahaj yogis/ yogins about him/her? What is the feedback regarding their moral character?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.feedbackfromOthers || ''}
                                                        name="feedbackfromOthers"
                                                        onChange={changeHandler}
                                                        id="feedbackfromOthers"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".feedbackfromOthers"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Are the parents or his family members in Sahaj yoga? If yes, then for how long? Do they approve his/her participation in Sahaja Yoga?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.parents || ''}
                                                        name="parents"
                                                        onChange={changeHandler}
                                                        id="parents"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".parents"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Is the Sahaj culture reflected in their family ?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.sahajCulture || ''}
                                                        name="sahajCulture"
                                                        onChange={changeHandler}
                                                        id="sahajCulture"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".sahajCulture"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Have the parents and candidate been briefed about the importance of marriages in Sahaj Yoga?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.importanceOfMarriage || ''}
                                                        name="importanceOfMarriage"
                                                        onChange={changeHandler}
                                                        id="importanceOfMarriage"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".importanceOfMarriage"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Does the boy/girl have any reservations regarding the marriage?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.applicantsReservations || ''}
                                                        name="applicantsReservations"
                                                        onChange={changeHandler}
                                                        id="applicantsReservations"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantsReservations"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Do the parents have any reservations regarding the marriage?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.parentsReservations || ''}
                                                        name="parentsReservations"
                                                        onChange={changeHandler}
                                                        id="parentsReservations"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".parentsReservations"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Are the Parents and the candidate both committed to make the Sahaja marriage a success
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.commitment || ''}
                                                        name="commitment"
                                                        onChange={changeHandler}
                                                        id="commitment"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".commitment"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    In your opinion, can he/ she be a good candidate for Sahaja marriage to make it a success?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.success || ''}
                                                        name="success"
                                                        onChange={changeHandler}
                                                        id="success"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".success"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Have the educational certificates been verified?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.educationalCertificate || ''}
                                                        name="educationalCertificate"
                                                        onChange={changeHandler}
                                                        id="educationalCertificate"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".educationalCertificate"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Has the income or salary been verified?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.incomeSalary || ''}
                                                        name="incomeSalary"
                                                        onChange={changeHandler}
                                                        id="incomeSalary"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".incomeSalary"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Any current or earlier health related problems of the candidate?
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.healthProblem || ''}
                                                        name="healthProblem"
                                                        onChange={changeHandler}
                                                        id="healthProblem"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".healthProblem"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Is the candidate a divorcee from an earlier Sahaja Marriage? If so give details of the boy/girl, their families and their address and tele nos.
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.divorcee || ''}
                                                        name="divorcee"
                                                        onChange={changeHandler}
                                                        id="divorcee"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".divorcee"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <MDButton color="info" onClick={submitHandler} disabled={scFeedback}>
                                            SAVE
                                        </MDButton>
                                    }
                                    {/* {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null} */}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default DistrictCoordinatorFeedback;