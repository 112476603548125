
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Select from "react-select";
import * as service from '../../services/service';

function StateCoordinatorFeedback() {
    const [state, setState] = useState({ feedbackId: 0, feedback: '', remarks: '' });
    // const [data, setData] = useState([]);
    const [applicationId, setApplicationId] = useState('');
    const [errors, setErrors] = useState({});   
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();

    const getFeedback = (appId) => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_STATE_COORDINATOR_FEEDBACK,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ApplicationId: appId,
                        RegistrationId: params.id
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, feedbackId: result.feedbackId, feedback: result.feedback, remarks: result.remarks
                        });
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    const getApplicationById = () => {
        setLoading(true);
        // const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_APPLICATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        RegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        // setData(result); 
                        
                        setApplicationId(result.applicationId);
                        getFeedback(result.applicationId);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

   

    useEffect(() => {
        setLoading(true);
        getApplicationById();      
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.feedback) {
            isValid = false;
            errs[".feedback"] = "Please enter";
        }        
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
        fetch(
            service.ADD_UPDATE_STATE_COORDINATOR_FEEDBACK,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    FeedbackId: state.feedbackId ? state.feedbackId : 0,
                    RegistrationId: params.id,
                    ApplicationId: applicationId,
                    Feedback: state.feedback,
                    Remarks: state.remarks,                    
                    AddedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                    UpdatedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.messageType === 'Success') {
                    // eslint-disable-next-line
                    alert(result.message);
                    navigate('/marriage/marriageforms');
                }
                else if (result.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message);
                    navigate('/marriage/marriageforms');
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center', }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    Feedback
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.feedback || ''}
                                                        name="feedback"
                                                        onChange={changeHandler}
                                                        id="feedback"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".feedback"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Remarks
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput fullWidth value={state.remarks || ''}
                                                        name="remarks"
                                                        onChange={changeHandler}
                                                        id="remarks"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remarks"]}</div>
                                                </TableCell>
                                            </TableRow>                                           
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <MDButton color="info" onClick={submitHandler}>
                                            SAVE
                                        </MDButton>
                                    }
                                    {/* {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null} */}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default StateCoordinatorFeedback;