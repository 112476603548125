/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
import { useEffect, useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
// import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
// import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
// import booking1 from "assets/images/products/product-1-min.jpg";
// import booking2 from "assets/images/products/product-2-min.jpg";
// import booking3 from "assets/images/products/product-3-min.jpg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as service from '../../../services/service';

function Analytics() {
  const [countData, setCountData] = useState([]);
  const [cityCountData, setCityCountData] = useState([]);

  const getTotalCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_TOTAL_COUNTS,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setCountData(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getCityWiseCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_CITY_WISE_COUNT,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {       
        setCityCountData(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  useEffect(() => {
    getTotalCounts();
    getCityWiseCounts();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="people"
                  title="Total"
                  count={countData.totalCount}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="boy"
                  title="Male"
                  count={countData.maleCount}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="face"
                  title="Female"
                  count={countData.femaleCount}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="people"
                  title="Single Male"
                  count={countData.singleMale}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="boy"
                  title="Single Female"
                  count={countData.singleFemale}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="face"
                  title="Divorcee Male"
                  count={countData.divorceeMale}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="face"
                  title="Divorcee Female"
                  count={countData.divorceeFemale}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <br/>
      <Grid>
          <div style={{ fontSize: '14px', fontWeight: 'bold',}}>City Wise Analysis</div>
          <MDBox mb={1.5}>
            <TableContainer component={Paper}>
              <Table style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                <TableRow>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    State
                  </TableCell>
                  <TableCell  align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    City
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    District
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Male Count
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Female Count
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total Count
                  </TableCell>
                </TableRow>                
                <TableBody>
                  {cityCountData.map((row) => (
                    <TableRow>
                      <TableCell align="center" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.state}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.district}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.city}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold',"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.maleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.femaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px' , "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                        {row.totalCount}
                      </TableCell>                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MDBox>
        </Grid>
     
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
